import { css, CSSObject } from "styled-components";

// can't use this yet, styled-components bug
// interface MediaQueryProps {
//   [key: string]: {
//     width?: {
//       max?: string | number;
//       min?: string | number;
//     };
//     height?: {
//       max?: string | number;
//       min?: string | number;
//     };
//   };
// }

export const sizes: any = {
  toPhoneSmall: {
    width: {
      max: 330,
    },
  },
  toPhone: {
    width: {
      max: 750,
    },
  },
  toPhoneLarge: {
    width: {
      max: 750,
    },
    height: {
      max: 600,
    },
  },
  toTablet: {
    width: {
      max: 1199,
    },
    height: {
      min: 750,
    },
  },
  fromTablet: {
    width: {
      min: 751,
    },
  },
  tabletP: {
    width: {
      min: 751,
      max: 900,
    },
  },
  toTabletP: {
    width: {
      max: 900,
    },
  },
  toTabletMedium: {
    width: {
      max: 1080,
    },
  },
  tabletL: {
    width: {
      min: 901,
      max: 1099,
    },
  },
  fromTabletL: {
    width: {
      min: 1099,
    },
  },
  tablet: {
    width: {
      min: 751,
      max: 1199,
    },
  },
  toLaptopSmall: {
    width: {
      max: 1300,
    },
  },
  toLaptopMedium: {
    width: {
      max: 1400,
    },
  },
  toLaptop: {
    width: {
      max: 1600,
    },
  },
  fromLaptop: {
    width: {
      max: 1200,
    },
  },
  laptop: {
    width: {
      min: 1200,
      max: 1600,
    },
  },
  desktop: {
    width: {
      min: 1601,
    },
  },
};

export default Object.keys(sizes).reduce(
  (acc: any, label: string) => {
    const mediaQueries = [
      ...(
        ["width", "height"].map(
          direction =>
            Object.keys(sizes[label][direction] || {}).map(
              item =>
                item &&
                `(${item}-${direction}: ${sizes?.[label]?.[direction]?.[item]}px)`,
            ) || [],
        ) || []
      )?.flat(),
    ];

    const mediaQueriesStr = mediaQueries
      .map(
        (item: string, key: number) =>
          item + (key !== mediaQueries.length - 1 ? " and " : ""),
      )
      .join("");

    acc[label] = (args: CSSObject) => {
      return css`
        @media only screen and ${mediaQueriesStr} {
          ${css(args)}
        }
      `;
    };

    return acc;
  },
  {},
);
