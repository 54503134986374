import React from "react";

import ThemeProvider from "../styles/ThemeProvider";
import Header from "../ui/layouts/Header";
import Footer from "@layouts/Footer";
import SEO from "./seo";

interface LayoutProps {
  header?: any;
  children?: any;
  seo?: any;
  hasFooter?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  header,
  seo = {},
  children,
  hasFooter = true,
}) => (
  <>
    <SEO {...seo} />
    <Header color={header?.color}>{header?.children}</Header>
    <ThemeProvider />
    <main>{children}</main>
    {hasFooter && <Footer />}
  </>
);

export default Layout;
