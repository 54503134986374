import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "gatsby";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

import NavbarLink from "./components/NavbarLink";
import NavbarMobile from "./components/NavbarMobile";
import StyledNavbar from "./Navbar.styled";
import StyledNavbarContainer from "./components/NavbarContainer";
import StyledNavbarLinksList from "./components/NavbarLinksList";
import StyledNavbarHamburger from "./components/NavbarHamburger.styled";

import { FiMenu } from "react-icons/fi";

import { NavbarProps } from "./types";

import Logo from "@components/Logo";

export const navLinks = [
  {
    key: "categories",
    name: "Home",
    to: "/",
  },
  {
    key: "templates",
    name: "Templates",
    to: "/templates",
  },
  {
    key: "components",
    name: "Components",
    to: "/components",
  },
  {
    key: "blog",
    name: "Blog",
    to: "/posts",
  },
];

const Navbar: React.FC<NavbarProps> = ({ color }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    isScrolled();
    window.addEventListener("scroll", isScrolled);
    window.addEventListener("resize", _.throttle(isScrolled, 150));

    return () => {
      window.removeEventListener(
        "resize",
        _.throttle(checkIfMobile, 150),
        true,
      );
      window.removeEventListener(
        "scroll",
        _.throttle(isScrolled, 150),
        true,
      );
      window.removeEventListener(
        "resize",
        _.throttle(isScrolled, 150),
      );
      // enableBodyScroll();
    };
  }, []);

  const handleMenuTrigger = () => {
    if (!isMenuActive) {
      // disableBodyScroll();
    } else {
      // enableBodyScroll();
    }
    setIsMenuActive(!isMenuActive);
  };

  const isScrolled = () => {
    const scrolled = window.pageYOffset;

    if (scrolled > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 900);
  };

  return (
    <StyledNavbar
      color={color}
      isBlack={color === "black" || isVisible === true}
    >
      <StyledNavbarContainer>
        <Link to="/">
          <Logo />
        </Link>

        <StyledNavbarLinksList>
          {navLinks.map(({ key, name, to, isExternal }) => {
            return (
              <NavbarLink
                key={key}
                title={name}
                to={to}
                isExternal={isExternal}
              />
            );
          })}
        </StyledNavbarLinksList>
        <StyledNavbarHamburger onClick={handleMenuTrigger}>
          <FiMenu />
        </StyledNavbarHamburger>
      </StyledNavbarContainer>
      {isMobile && (
        <NavbarMobile
          isMenuActive={isMenuActive}
          links={navLinks}
          handleMenuTrigger={handleMenuTrigger}
        />
      )}
    </StyledNavbar>
  );
};

export default Navbar;
